<template>
    <b-card class="card-accent-warning" header-tag="header" footer-tag="footer" no-body>
        <template v-slot:header>
            <i class="fa-regular fa-hand-dots"></i> <strong>Allergies</strong>
            <div class="card-header-actions">
                <b-link class="card-header-action btn-minimize" @click="visible = !visible">
                    <i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
                </b-link>
            </div>
        </template>
        <b-collapse v-model="visible">
        <b-overlay :show="confirm" no-wrap @shown="onShown">
            <template #overlay>
                <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                    <p class="text-center">
                        <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE ALLERGY INFO ON {{ toLocaleDate(selected?.created) }} </strong>
                    </p>
                    <div class="text-center">
                        <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                        <b-button variant="outline-success" @click="onConfirmed(selected)">Confirm</b-button>
                    </div>
                </div>
            </template>
        </b-overlay>
        <b-overlay 
            :show="$store.getters['loading/isLoading']('allergies')"
            rounded="sm"
            variant="light"
            :opacity="0.80"
            blur="2px">
            <b-card-body class="p-0">
                <b-table
                    ref="table"
                    class="m-0"
                    responsive
                    stacked="sm"
                    show-empty
                    borderless
                    striped
                    hover
                    primary-key="id"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :items="items"
                    :fields="fields"
                    :busy.sync="$store.getters['loading/isLoading']('allergies')"
                    :no-sort-reset="true"
                    :sort-by.sync="sortedBy"
                    :sort-desc.sync="sortedDesc"
                    :no-border-collapse="false">

                    <template v-slot:cell(identification_date)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? toLocaleDate(data.value) : 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="danger" @click="onRemoveItem(row.item)" class="mr-1"><i class="fa-light fa-xmark"></i></b-button>
                    </template>

                    <div slot="table-busy" class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>

                    <template v-slot:empty="scope">
                        <div class="small text-muted text-center my-2">
                            <span>n/a</span>
                        </div>
                    </template>

                    <template v-slot:custom-foot="scope">
                        <b-tr class="border-top">
                            <b-td colspan="5" class="align-middle" variant="light" style="background: #fff;">
                                Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                            </b-td>
                            <b-td colspan="2" class="text-right align-middle" variant="light" style="background: #fff;">
                                <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                            </b-td>
                        </b-tr>
                    </template>
                </b-table>
                <b-card-footer>
                <b-button @click="addNew = !addNew" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
                <b-collapse id="add" v-model="addNew" variant="primary">
                    <b-card class="mt-3">
                        <validation-observer ref="observer" v-slot="{ passes }">
                        <b-form @submit.prevent="passes(onFormSubmit)" novalidate>

                      <validation-provider vid="allergy_type_id" v-slot="{ errors }">
                        <b-form-group
                            label-cols-sm="2"
                            label-cols-lg="3"
                            label="Allergy Type"
                            label-for="allergy_type_id"
                            :invalid-feedback="errors[0]"
                            :state="errors[0] ? false : null">
                            <b-form-select id="allergy_type_id" name="allergy_type_id" plain v-model="form.allergy_type_id" @change="onAllergyTypeSelected">
                                <b-form-select-option disabled :value="null">* Select Type</b-form-select-option>
                                <b-form-select-option v-for="allergy_type in allergy_types" :key="allergy_type.id" :value="allergy_type.id">{{ allergy_type.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                        </validation-provider>
                        <template v-if="typeSelected">
                            <validation-provider vid="allergy_code_id" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="2"
                                    label-cols-lg="3"
                                    label="Allergy"
                                    label-for="allergy_code_id"
                                    :invalid-feedback="errors[0]"
                                    :state="errors[0] ? false : null">
                                    <vue-bootstrap-autocomplete id="allergy_code_id" name="allergy_code_id" v-model="query" :data="codes" placeholder="* Search Allergy..." :minMatchingChars="1" :serializer="allergySerializer" @hit="onHitAllergy">
                                        <template slot="suggestion" slot-scope="{ data, htmlText }">
                                            <div class="d-flex align-items-center">
                                                <!-- Note: the v-html binding is used, as htmlText contains
                                                the suggestion text highlighted with <strong> tags -->
                                                <span class="ml-1" v-html="htmlText"></span>
                                            </div>
                                        </template>
                                    </vue-bootstrap-autocomplete>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider vid="allergy_severity_id" v-slot="{ errors }">
                            <b-form-group
                                label-cols-sm="2"
                                label-cols-lg="3"
                                label="Severity"
                                label-for="allergy_severity_id"
                                :invalid-feedback="errors[0]"
                                :state="errors[0] ? false : null">
                                <b-form-select id="allergy_severity_id" name="allergy_severity_id" plain v-model="form.allergy_severity_id">
                                    <b-form-select-option disabled :value="null">* Select Severity</b-form-select-option>
                                    <b-form-select-option v-for="severity in allergy_severities" :key="severity.id" :value="severity.id">{{ severity.name }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            </validation-provider>

                            <validation-provider vid="reaction" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="2"
                                    label-cols-lg="3"
                                    label="Reaction"
                                    label-for="reaction"
                                    :invalid-feedback="errors[0]"
                                    :state="errors[0] ? false : null">
                                    <b-form-input id="reaction" name="reaction" placeholder="Add allergic reaction..." v-model="form.reaction" />
                                </b-form-group>
                            </validation-provider>

                            <validation-provider vid="identification_date" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="2"
                                    label-cols-lg="3"
                                    label="Identification date"
                                    label-for="identification_date"
                                    :invalid-feedback="errors[0]"
                                    :state="errors[0] ? false : null">
                                    <b-form-datepicker id="identification_date" placeholder="Set identification date..." v-model="form.identification_date" :locale="currentLang" :max="maxDate"></b-form-datepicker>

                                </b-form-group>
                            </validation-provider>
                            <b-button class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
                            <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
                        </template>

                        </b-form>
                        </validation-observer>
                    </b-card>
                </b-collapse>
                </b-card-footer>
            </b-card-body>
        </b-overlay>
        </b-collapse>
    </b-card>
</template>
<script>
import ComponentExtension from '../ComponentExtension'
import { mapGetters, mapState, mapActions } from "vuex"

export default ComponentExtension.extend({
    name: "allergies",
    data() {
        return {
            addNew: false,
            form: new Form({
                allergy_code_id: null,
                allergy_type_id: null,
                allergy_severity_id: null,
                reaction: "",
                identification_date: null,
                consistency_token: null
            }),

            fields: [
                { key: 'identification_date', label: 'ID Date', class: 'align-middle' },
                { key: 'allergy_type.name', label: 'Type', class: 'align-middle text-center' },
                { key: 'allergy_code.name', label: 'Code', class: 'align-middle text-center' },
                { key: 'allergy_severity.name', label: 'Severity', class: 'align-middle text-center' },
                { key: 'reaction', label: 'Reaction', class: 'align-middle text-center' },
                
                { key: "actions", label: "", class: "align-middle text-right" },
            ],

            currentPage: 1,
            perPage: 5,
            sortedBy: 'created',
            sortedDesc: true,

            query: "",

            isLocked: true,
            isDirty: false,
            hasErrors: false,

            confirm: false,
            selected: null,

            maxDate: new Date(),

            codes: [],
        }
    },
    computed: {
        ...mapGetters('health', ['getPatientAllergies']),
        items() {
            return this.getPatientAllergies()
        },
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
            // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        },
        ...mapState('catalogue', {
            allergy_codes: state => state.allergy_codes,
            allergy_types: state => state.allergy_types,
            allergy_severities: state => state.allergy_severities
        }),

        typeSelected() {
            return this.form.allergy_type_id !== null
        },
        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.dirty(this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.saved(this.$vnode.key)
                }
            }
        },
        addNew(val) {
            if (!val) {
                this.reset()
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('health', ['getAllergiesByPatientId', 'createPatientAllergy', 'deletePatientAllergy']),
        
        onRemoveItem(item) {
            this.confirm = true
            this.selected = item
        },
        onConfirmed(item) {

            this.processing = true

            this.deletePatientAllergy(item.id).then(() => {
                this.notify("Patient allergy removed", "Patient allergy was removed successfully")
                this.confirm = false
                this.selected = null

            })
            .catch(errors => {
                if (errors.response) {
                    this.notify("Error", "Something went wrong", 'danger')
                    this.confirm = false
                    this.selected = null
                }
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
            this.selected = null
        },
        onAllergyTypeSelected(id) {
            this.query = ""
            if (id) {
                const type = this.allergy_types.find(type => type.id === id)
                this.codes = this.allergy_codes.filter(code => code.parent === type.code)
            }
        },
        allergySerializer(item) {
            return item.name
        },
        onHitAllergy(item) {
            this.form.populate({allergy_code_id: item.id})
        },
        onFormSubmit() {
            this.createPatientAllergy(this.form.data()).then(() => {
                this.reset()
                this.notify("Patient allergy created", "Patient allergy was created successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer.setErrors(errors.response.data)
                }
            })
        },
        reset() {
            this.form.reset()
            this.$refs.observer.reset()

            this.$nextTick(() => {
                this.query = ""
                this.hasErrors = false
                this.addNew = false
                this.saved(this.$vnode.key)
            })
        }
    },
    async mounted() {
        await this.getCatalogueItem('allergy_codes')
        await this.getCatalogueItem('allergy_types')
        await this.getCatalogueItem('allergy_severities')
        await this.getAllergiesByPatientId()

    }
})
</script>