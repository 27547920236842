<template>
    <b-card class="card-accent-secondary" header-tag="header" footer-tag="footer" no-body>
        <template v-slot:header>
            <i class="fa-regular fa-weight-scale"></i> <strong> Measurements </strong>
            <div class="card-header-actions">
                <b-link class="card-header-action btn-minimize" @click="visible = !visible">
                    <i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
                </b-link>
            </div>
        </template>
        <b-collapse v-model="visible">
        <b-overlay :show="confirm" no-wrap @shown="onShown">
            <template #overlay>
                <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                    <p class="text-center">
                        <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE MEASUREMENTS INFO ON {{ toLocaleDate(selected?.created) }} </strong>
                    </p>
                    <div class="text-center">
                        <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                        <b-button variant="outline-success" @click="onConfirmed(selected)">Confirm</b-button>
                    </div>
                </div>
            </template>
        </b-overlay>
        <b-overlay 
            :show="$store.getters['loading/isLoading']('measurements')"
            rounded="sm"
            variant="light"
            :opacity="0.80"
            blur="2px">
            <b-card-body class="p-0">
                <b-table
                    ref="table"
                    class="m-0"
                    responsive
                    stacked="sm"
                    show-empty
                    borderless
                    striped
                    hover
                    primary-key="id"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :items="items"
                    :fields="fields"
                    :busy.sync="$store.getters['loading/isLoading']('measurements')"
                    :no-sort-reset="true"
                    :sort-by.sync="sortedBy"
                    :sort-desc.sync="sortedDesc"
                    :no-border-collapse="false">

                    <template v-slot:cell(height)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? data.value+" cm" : 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(weight)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? data.value + " " + data.item.weight_unit : 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(bmi)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? data.value : 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(waist)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? data.value+" cm" : 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(hip)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? data.value+" cm" : 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(glucose)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? data.value+" mmol/L" : 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="danger" @click="onRemoveItem(row.item)" class="mr-1"><i class="fa-light fa-xmark"></i></b-button>
                    </template>

                    <div slot="table-busy" class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>

                    <template v-slot:empty="scope">
                        <div class="small text-muted text-center my-2">
                            <span>n/a</span>
                        </div>
                    </template>

                    <template v-slot:custom-foot="scope">
                        <b-tr class="border-top">
                            <b-td colspan="6" class="align-middle" variant="light" style="background: #fff;">
                                Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                            </b-td>
                            <b-td colspan="2" class="text-right align-middle" variant="light" style="background: #fff;">
                                <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                            </b-td>
                        </b-tr>
                    </template>
                </b-table>
                <b-card-footer>
                <b-button @click="addNew = !addNew" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
                <b-collapse id="add" v-model="addNew" variant="primary">
                    <b-card class="mt-3">
                        <validation-observer ref="observer" v-slot="{ passes }">
                        <b-form @submit.prevent="passes(onFormSubmit)" novalidate>

                        <validation-provider vid="non_field_errors" v-slot="{ errors }">
                          <b-form-invalid-feedback :state="errors[0] ? false : null">
                            <h6 class="d-block p-3 bg-danger ">{{ errors[0] }}</h6>
                          </b-form-invalid-feedback>
                        </validation-provider>

                            <validation-provider name="Height" vid="height" rules="measurement" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="2"
                                    label-cols-lg="2"
                                    label="Height"
                                    label-for="height"
                                    :invalid-feedback="errors[0]"
                                    :state="errors[0] ? false : null">
                                     <b-input-group>
                                        <b-form-input type="number" id="height" v-comma2dot name="height" placeholder=".00" v-model="form.height"  />
                                        <template #append>
                                            <b-form-select id="height_unit" name="height_unit" class="squared-left" plain v-model="form.height_unit">
                                                <b-form-select-option :value="null">Height Unit</b-form-select-option>
                                                <b-form-select-option value="ft">ft</b-form-select-option>
                                                <b-form-select-option value="inch">inch</b-form-select-option>
                                                <b-form-select-option value="m">m</b-form-select-option>
                                                <b-form-select-option value="cm">cm</b-form-select-option>
                                            </b-form-select>
                                        </template>
                                    </b-input-group>
                                     <validation-provider vid="height_unit" v-slot="{ errors }">
                                        <b-form-invalid-feedback :state="errors[0] ? false : null">
                                            {{ errors[0] }}
                                          </b-form-invalid-feedback>
                                    </validation-provider>
                                    </b-input-group>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider name="Weight" vid="weight" rules="measurement" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="2"
                                    label-cols-lg="2"
                                    label="Weight"
                                    label-for="weight"
                                    :invalid-feedback="errors[0]"
                                    :state="errors[0] ? false : null">
                                    <b-input-group>
                                        <b-form-input type="number" id="weight" v-comma2dot name="weight" placeholder=".00" v-model="form.weight" />
                                        <template #append>
                                            <b-form-select id="weight_unit" name="weight_unit" class="squared-left" plain v-model="form.weight_unit">
                                                <b-form-select-option :value="null">Weight Unit</b-form-select-option>
                                                <b-form-select-option value="g">g</b-form-select-option>
                                                <b-form-select-option value="kg">kg</b-form-select-option>
                                                <b-form-select-option value="lb">lb</b-form-select-option>
                                            </b-form-select>
                                        </template>
                                    </b-input-group>
                                     <validation-provider vid="weight_unit" v-slot="{ errors }">
                                        <b-form-invalid-feedback :state="errors[0] ? false : null">
                                            {{ errors[0] }}
                                          </b-form-invalid-feedback>
                                    </validation-provider>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider name="Waist circumference" vid="waist" rules="measurement" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="2"
                                    label-cols-lg="2"
                                    label="Waist circumference"
                                    label-for="waist"
                                    :invalid-feedback="errors[0]"
                                    :state="errors[0] ? false : null">
                                    <b-input-group append="cm">
                                        <b-form-input type="number" id="waist" v-comma2dot name="waist" placeholder=".00" v-model="form.waist" />
                                    </b-input-group>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider name="Hip" vid="hip" rules="double:2" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="2"
                                    label-cols-lg="2"
                                    label="Hip "
                                    label-for="hip"
                                    :invalid-feedback="errors[0]"
                                    :state="errors[0] ? false : null">
                                    <b-input-group append="cm">
                                        <b-form-input type="number" id="hip" v-comma2dot name="hip" placeholder=".00" v-model="form.hip" />
                                    </b-input-group>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider name="Glucose" vid="glucose" rules="measurement" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="2"
                                    label-cols-lg="2"
                                    label="Glucose"
                                    label-for="glucose"
                                    :invalid-feedback="errors[0]"
                                    :state="errors[0] ? false : null">
                                    <b-input-group append="mmol/L">
                                        <b-form-input type="number" id="glucose" v-comma2dot name="glucose" placeholder=".00" v-model="form.glucose" />
                                    </b-input-group>
                                </b-form-group>
                            </validation-provider>

                            <b-button class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
                            <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>

                        </b-form>
                        </validation-observer>
                    </b-card>
                </b-collapse>
                </b-card-footer>
                </b-card-body>

<!--                 <b-card class="border-left-0 border-right-0 border-bottom-0 squared-top" no-body>
                    <b-card-body class="pb-0">
                        <b-form novalidate>
                            <b-form-group :disabled="isLocked">
                                <b-form-group
                                    label-cols-sm="2"
                                    label-cols-lg="2"
                                    label="Calculated CV Risk"
                                    label-for="cv_risk">
                                     <b-input-group>
                                        <b-form-input id="cv_risk" v-comma2dot name="cv_risk"/>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group
                                    label-cols-sm="2"
                                    label-cols-lg="2"
                                    label="Obesitas"
                                    label-for="obesitas">
                                     <b-input-group>
                                        <b-form-input id="obesitas" v-comma2dot name="obesitas"/>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group
                                    label-cols-sm="2"
                                    label-cols-lg="2"
                                    label="Overweight"
                                    label-for="overweight">
                                     <b-input-group>
                                        <b-form-input id="overweight" v-comma2dot name="overweight"/>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group
                                    label-cols-sm="2"
                                    label-cols-lg="2"
                                    label="Uncontrolled HT"
                                    label-for="uncontrolled_ht">
                                     <b-input-group>
                                        <b-form-input id="uncontrolled_ht" v-comma2dot name="uncontrolled_ht"/>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group
                                    label="Treatment"
                                    label-for="treatment">
                                    <b-form-textarea id="treatment" name="treatment" placeholder="Add Treatment..." rows="4" />
                                </b-form-group>
                                <b-form-group
                                    label="Screening and Counseling"
                                    label-for="screening_counseling">
                                    <b-form-textarea id="screening_counseling" name="screening_counseling" placeholder="Add Screening and Counseling..." rows="4" />
                                </b-form-group>
                            </b-form-group>
                            <b-button class="mr-1" :disabled="isLocked" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
                            <b-button v-if="isLocked" type="button" variant="warning" @click="isLocked = false"><i class="fa-solid fa-unlock"></i> Unlock</b-button>
                            <b-button v-if="!isLocked && !isDirty && !hasErrors" type="button" variant="outline-primary" @click="isLocked = true"><i class="fa-solid fa-lock"></i> Lock</b-button>
                            <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>
                        </b-form>
                    </b-card-body>
                </b-card> -->
            </b-overlay>
        </b-collapse>
    </b-card>
</template>
<script>
import ComponentExtension from '../ComponentExtension'
import { mapGetters, mapState, mapActions } from "vuex"

export default ComponentExtension.extend({
    name: "measurements",
    data() {
        return {
            addNew: false,
            form: null,
            fields: [
                { key: 'created', label: 'Date/Time', class: 'align-middle text-center', formatter: (value) => this.toLocaleDate(value) },
                { key: 'weight', label: 'Weight', class: 'align-middle text-center' },
                { key: 'height', label: 'Height', class: 'align-middle text-center' },
                { key: 'bmi', label: 'BMI', class: 'align-middle text-center' },
                { key: 'waist', label: 'Waist circumference', class: 'align-middle text-center' },
                { key: 'hip', label: 'Hip circumference', class: 'align-middle text-center' },
                { key: 'glucose', label: 'Glucose', class: 'align-middle text-center' },
                
                { key: "actions", label: "", class: "align-middle text-right" },
            ],

            currentPage: 1,
            perPage: 5,
            sortedBy: 'created',
            sortedDesc: true,

            isLocked: true,
            isDirty: false,
            hasErrors: false,

            confirm: false,
            processing: false,
            selected: null
        }
    },
    computed: {
        ...mapGetters('health', ['getPatientMeasurements']),
        items() {
            return this.getPatientMeasurements()
        },
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        },
        ...mapState('catalogue', {
            body_weight_measurement_methods: state => state.body_weight_measurement_methods,
            body_height_measurement_methods: state => state.body_height_measurement_methods
        }),

        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                if(!this.bypass) {
                    this.isDirty = this.form.dirty()
                    this.hasErrors = !this.formValid

                    if (this.isDirty) {
                        this.dirty(this.$vnode.key)
                    }else{
                        if (this.formValid)
                            this.saved(this.$vnode.key)
                    }
                }
                this.bypass = false 
            }
        },
        catalogue_defaults: {
            immediate: true,
            handler(val) {
                this.newFormWithDefaults(val)
            }
        },
        addNew(val) {
            if (!val) {
                this.reset()
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('health', ['getMeasurementsByPatientId', 'createPatientMeasurements', 'deletePatientMeasurements']),
        onRemoveItem(item) {
            this.confirm = true
            this.processing = false
            this.selected = item
        },
        onConfirmed(item) {

            this.processing = true

            this.deletePatientMeasurements(item.id).then(() => {
                this.notify("Patient measurements removed", "Patient measurements was removed successfully")
                this.confirm = false
                this.processing = false
                this.selected = null

            })
            .catch(errors => {
                this.notify("Error", "Something went wrong", 'danger')
                this.confirm = false
                this.processing = false
                this.selected = null
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
            this.processing = false
            this.selected = null
        },

        onFormSubmit() {
            this.createPatientMeasurements(this.form.data()).then(() => {
                this.reset()
                this.notify("Patient measurements created", "Patient measurements was created successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer.setErrors(errors.response.data)
                }
            })
        },
        newFormWithDefaults(defaults) {
            this.form = Form.create({
                height: "",
                height_unit: defaults.body_height_unit,
                weight: "",
                weight_unit: defaults.body_weight_unit,
                waist: "",
                hip: "",
                glucose: "",
                consistency_token: null
            })
        },
        reset() {
            this.newFormWithDefaults(this.catalogue_defaults)
            this.$refs.observer.reset()

            this.$nextTick(() => {
                this.hasErrors = false
                this.addNew = false
                this.saved(this.$vnode.key)
            })
        }
    },
    async mounted() {
        await this.getCatalogueItem('body_weight_measurement_methods')
        await this.getCatalogueItem('body_height_measurement_methods')

        this.getMeasurementsByPatientId()
    }
})
</script>