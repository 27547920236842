<template>
	<b-card class="card-accent-success" header-tag="header" footer-tag="footer" no-body>
		<template v-slot:header>
			<b-button variant="primary" pressed><i class="fa-regular fa-notes-medical"></i> Current Decursus</b-button>
			<div class="card-header-actions">
<!-- 				<b-link :disabled="noRevisions" class="card-header-action btn-setting" v-b-tooltip.hover title="Browse Revisions" @click="decursusRevisions">
					<i class="fa-regular fa-clock-rotate-left"></i>
				</b-link> -->
				<b-link class="card-header-action btn-setting" @click="$emit('minimized', false)">
					<i class="fa-regular fa-columns"></i>
				</b-link>
			</div>
		</template>
		<validation-observer ref="observer" v-slot="{ passes }">
		<b-form @submit.prevent="passes(onFormSubmit)" novalidate>
			<b-tabs pills card content-class="border-left-0 border-right-0">
				<b-tab active>
			        <b-overlay no-center :show="confirm" no-wrap @shown="onShown">
			            <template #overlay>
							<div class="position-absolute" style="bottom: 25px; right: 25%; left: 20%;">
				                <div v-if="$store.getters['loading/isLoading']('decursus')" class="d-flex align-items-center">
				                    <strong>Updating...</strong>
				                    <b-spinner class="ml-auto"></b-spinner>
				                </div>
				                <div v-else ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
				                    <p class="text-center">
				                        <i class="fa-solid fa-cloud-arrow-up font-sm text-success"></i> <strong class="text-success">UPDATE DECURSUS ? </strong>
				                    </p>
				                    <div class="text-center">
				                        <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
				                        <b-button variant="outline-success" @click="onConfirmed">Confirm</b-button>
				                    </div>
				                </div>
        					</div>
			            </template>
			        </b-overlay>
					<template slot="title">
						<i class="fa-regular fa-notes-medical"></i> <small>{{ toLocaleDate(decursus?.date) }}</small>
					</template>
					<b-form-group :disabled="true"
						label="Decursus Date"
						:label-for="`date-decursus-${decursus.id}`">
						<b-form-input :id="`date-decursus-${decursus.id}`" readonly v-dateformat autocomplete="off" v-model="decursus.date" />
		        	</b-form-group>

					<validation-provider vid="subjective" v-slot="{ errors }">
			        	<speech-area id="subjective" name="subjective" classes="squared-bottom" label="Subjective" :errors="errors[0]" v-model="form.subjective" placeholder="Add subjective..." :state="errors[0] ? false : (icpcSubjective ? true : null)" rows="4">
			        		<validation-provider vid="icpc_subjective_id" v-slot="{ errors }">
							  <b-button
							    block
							    type="button"
							    class="squared-top"
							    :variant="errors[0] ? 'danger' : (icpcSubjective ? 'success' : 'primary')"
							    @click="icpcSubjective ? setICPC('icpc_subjective', null) : showICPCModal('icpc_subjective')"
							  >
							    <i :class="icpcSubjective ? 'fa-solid fa-ban' : 'fa-solid fa-seal'"></i>
							    {{ icpcSubjective ? icpcSubjective : 'ICPC Subjective' }}
							  </b-button>
							</validation-provider>
			        	</speech-area>
					</validation-provider>

	                <validation-provider vid="objective" v-slot="{ errors }">
			        	<speech-area id="objective" name="objective" label="Objective" :errors="errors[0]" v-model="form.objective" placeholder="Add objective..." :state="errors[0] ? false : null" rows="4">
			        	</speech-area>
					</validation-provider>

					<validation-provider vid="evaluation" v-slot="{ errors }">
			        	<speech-area id="evaluation" name="evaluation" classes="squared-bottom" label="Evaluation" :errors="errors[0]" v-model="form.evaluation" placeholder="Add evaluation..." :state="errors[0] ? false : (icpcEvaluation ? true : null)" rows="4">
			        		<validation-provider vid="icpc_evaluation_id" v-slot="{ errors }">
							  <b-button
							    block
							    type="button"
							    class="squared-top"
							    :variant="errors[0] ? 'danger' : (icpcEvaluation ? 'success' : 'primary')"
							    @click="icpcEvaluation ? setICPC('icpc_evaluation', null) : showICPCModal('icpc_evaluation')"
							  >
							    <i :class="icpcEvaluation ? 'fa-solid fa-ban' : 'fa-solid fa-seal'"></i>
							    {{ icpcEvaluation ? icpcEvaluation : 'ICPC Evaluation' }}
							  </b-button>
							</validation-provider>
			        	</speech-area>
					</validation-provider>

	                <validation-provider vid="policy" v-slot="{ errors }">
			        	<speech-area id="policy" name="policy" label="Policy" :errors="errors[0]" v-model="form.policy" placeholder="Add policy..." :state="errors[0] ? false : null" rows="4">
			        	</speech-area>
					</validation-provider>

	                <validation-provider vid="comments" v-slot="{ errors }">
			        	<speech-area id="comments" name="comments" label="Comments" :errors="errors[0]" v-model="form.comments" placeholder="Add comments..." :state="errors[0] ? false : null" rows="4">
			        	</speech-area>
					</validation-provider>

	                <validation-provider vid="non_field_errors" v-slot="{ errors }">
				      <b-form-invalid-feedback :state="errors[0] ? false : null">
				      	<h6 class="d-block p-3 bg-danger ">{{ errors[0] }}</h6>
				      </b-form-invalid-feedback>
					</validation-provider>

				</b-tab>
			</b-tabs>
			<Referral :limit="5"/>
			<Episode @validated="episodeSaved = $event" :limit="5"/>
			<b-card-footer class="border-0">
				<b-button :disabled="$store.getters['loading/isLoading']('decursus') || !episodeSaved" type="submit" variant="success">
					<i class="fa-regular fa-circle-dot"></i> Save Decursus
				</b-button>
		    </b-card-footer>
		</b-form>
		</validation-observer>
	</b-card>
</template>
<script>
import Vue from 'vue'
import BaseExtension from '@/views/BaseExtension'
import { mapGetters, mapState, mapActions } from 'vuex'

import ICPCModal from '../catalogue/ICPCModal'
import EpisodeModal from './EpisodeModal'
import Episode from './Episode'
import Referral from './Referral'

export default BaseExtension.extend({
	name: 'decursus-current',
	components: {
		Referral,
		Episode
	},
	data() {
		return {
			form: new Form({
				episode: null,
				subjective: "",
				icpc_subjective_id: null,
				objective: "",
				evaluation: "",
				icpc_evaluation_id: null,
				policy: "",
				comments: "",
				consistency_token: null
			}),

            confirm: false,

            isDirty: false,
            hasErrors: false,

            episodeSaved: true,
		}
	},
    computed: {
        ...mapGetters('decursus', ['getCurrentDecursus']),
        decursus() {
            return this.getCurrentDecursus()
        },
        ...mapGetters('revisions', ['getRevisionsForDecursus']),
        revisions() {
            return this.getRevisionsForDecursus()
        },
        noRevisions() {
            return this.revisions.length < 2
        },
        ...mapGetters('episodes', ['getCurrentEpisode']),
        episode() {
            return this.getCurrentEpisode()
        },
        icpcSubjective() {
        	return this.form.icpc_subjective_id ? `${this.form.icpc_subjective.code} ${this.form.icpc_subjective.name}` : null
        },
        icpcEvaluation() {
        	return this.form.icpc_evaluation_id ? `${this.form.icpc_evaluation.code} ${this.form.icpc_evaluation.name}` : null
        },
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
        	// loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        }
    },
    watch: {
        decursus: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.form.withData(val)
                	this.getDecursusRevisions(val.id)
                }
            }
        },
		episode: {
			immediate: true,
			handler(val) {
                this.form.populate({episode: val.id})
			}
		},
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.dirty(this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.saved(this.$vnode.key)
                }
            }
        }
    },
	methods: {
        ...mapActions('decursus', ['updateDecursus']),
        ...mapActions('revisions', ['getDecursusRevisions', 'revertDecursusRevision']),

        onFormSubmit() {
            this.confirm = true
        },
        onConfirmed() {
            this.updateDecursus(this.form.data()).then(() => {
                this.notify("Decursus updated", "Decursus was updated successfully")
                this.confirm = false

            })
            .catch(errors => {
                if (errors.response) {
	            	this.notify("Error", "Something went wrong", 'danger')
	                this.$refs.observer.setErrors(errors.response.data)
	                this.hasErrors = true
	                this.confirm = false
	            }
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
        },
        pasteIntoForm(field, value) {
        	this.form.populate({[field]: value})
        	this.notify("Field copied", `Field ${field} was updated`)
        },
		showICPCModal(field) {
			var ComponentClass = Vue.extend(ICPCModal)
			var instance = new ComponentClass({ parent: this })

			instance.$on("hidden", () => {
				instance.$destroy()
			})

			instance.$on("selected", (icpc) => {
				this.setICPC(field, icpc)
			})

			instance.$mount()
		},

		setICPC(field, icpc) {
			this.form.populate({[`${field}_id`]: icpc ? icpc.id : null})
			this.form.populate({[field]: icpc})

			this.$refs.observer.reset()
		},
        decursusRevisions() {
            this.browseRevisions('Decursus', this.revisions)
        },
        async reverted(version) {
            await this.revertDecursusRevision({id: this.decursus.id, version: version})
        },

		// toggleSpeech() {
		// 	this.isListening = !this.isListening

		// 	const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)()
		// 	recognition.continuous = true
		// 	recognition.interimResults = true
		// 	recognition.lang = this.speechLang
	    //     recognition.onresult = (event) => {
	    //         let transcript = Array.from(event.results)
	    //             .map((result) => result[0])
	    //             .map((result) => result.transcript)
	    //             .join(".");
	            
	    //         if (event.results[0].isFinal) {
	    //             console.log(transcript)

	    //             this.form.populate({subjective: transcript})
	    //         }
	    //     }

		// 	// recognition.onend = () => {
		// 	// 	console.log('END')
		// 	// }
		// 	recognition.start()
		// }
	},
	async mounted() {
		EventBus.$on('copied', payload => {
			this.pasteIntoForm(payload.field, payload.value)
		})

		// EventBus.$on('episode-saved', saved => {
		// 	this.episodeSaved = saved
		// })
	},
	beforeDestroy() {
		 EventBus.$off("copied")
		 // EventBus.$off("episode-saved")
	}
})
</script>