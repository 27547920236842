<template>
    <b-card class="card-accent-secondary" header-tag="header" footer-tag="footer" no-body>
        <template v-slot:header>
            <i class="fa-regular fa-flask-round-potion"></i> <strong>Lab Referrals</strong>
            <div class="card-header-actions">
                <b-link class="card-header-action" @click="showChartModal">
                    <i class="fa-regular fa-chart-line"></i>
                </b-link>

                <b-link class="card-header-action btn-minimize" @click="visible = !visible">
                    <i class="fa-regular" :class="visible ? 'fa-square-caret-up' : 'fa-square-caret-down'"></i>
                </b-link>
            </div>
        </template>
        <b-collapse v-model="visible">

        <b-overlay :show="confirm" no-wrap @shown="onShown">
            <template #overlay>
                <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                    <p class="text-center">
                        <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE LAB REFERRAL ON {{ toLocaleDate(selected?.created) }} </strong>
                    </p>
                    <div class="text-center">
                        <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                        <b-button variant="outline-success" @click="onConfirmed(selected)">Confirm</b-button>
                    </div>
                </div>
            </template>
        </b-overlay>
        <b-overlay 
            :show="$store.getters['loading/isLoading']('lab')"
            rounded="sm"
            variant="light"
            :opacity="0.80"
            blur="2px">
            <b-card-body class="p-0">
                <b-table
                    ref="table"
                    class="m-0"
                    responsive
                    stacked="sm"
                    show-empty
                    borderless
                    striped
                    hover
                    primary-key="id"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :items="items"
                    :fields="fields"
                    :busy.sync="$store.getters['loading/isLoading']('lab')"
                    :no-sort-reset="true"
                    :sort-by.sync="sortedBy"
                    :sort-desc.sync="sortedDesc"
                    :no-border-collapse="false">

                    <template v-slot:cell(identification_date)="data">
                        <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? toLocaleDate(data.value) : 'n/a' }} </span>
                    </template>

                    <template v-slot:cell(actions)="row">
                        <b-button-group size="sm">
                           <b-button v-if="row.item.document" variant="success" @click="openDocument(row.item)"><i class="fa fa-paperclip"></i></b-button>
                           <b-button variant="primary" @click="toggleDetails(row)"><i class="fa fa-ellipsis-h"></i></b-button>
                           <!-- <b-button variant="secondary" @click="showChartModal(row.item)"><i class="fa-regular fa-chart-line"></i></b-button> -->
                            <b-button variant="danger" @click="onRemoveItem(row.item)"><i class="fa-light fa-xmark"></i></b-button>
                        </b-button-group>
                    </template>

                    <template #row-details="row">
                        <UploadResultDocument :lab-referral="row.item"/>
                        <TestList :lab-referral="row.item"/>
                    </template>

                    <div slot="table-busy" class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>

                    <template v-slot:empty="scope">
                        <div class="small text-muted text-center my-2">
                            <span>n/a</span>
                        </div>
                    </template>

                    <template v-slot:custom-foot="scope">
                        <b-tr class="border-top">
                            <b-td colspan="3" class="align-middle" variant="light" style="background: #fff;">
                                Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                            </b-td>
                            <b-td colspan="2" class="text-right align-middle" variant="light" style="background: #fff;">
                                <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                            </b-td>
                        </b-tr>
                    </template>
                </b-table>
                <b-card-footer>
                <b-button @click="addNew = !addNew" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
                <b-collapse id="add" v-model="addNew" variant="primary">
                    <b-card class="mt-3">
                        <validation-observer ref="observer" v-slot="{ passes }">
                        <b-form @submit.prevent="passes(onFormSubmit)" novalidate>

                        <validation-provider vid="priority_id" v-slot="{ errors }">
                        <b-form-group
                            label-cols-sm="2"
                            label-cols-lg="3"
                            label="Referral Priority"
                            label-for="priority_id"
                            :invalid-feedback="errors[0]"
                            :state="errors[0] ? false : null">
                            <b-form-select id="priority_id" name="priority_id" plain v-model="form.priority_id">
                                <b-form-select-option disabled :value="null">* Select Referral Priority</b-form-select-option>
                                <b-form-select-option v-for="priority in referral_priorities" :key="priority.id" :value="priority.id">{{ priority.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                        </validation-provider>
                        <validation-provider vid="type_id" v-slot="{ errors }">
                        <b-form-group
                            label-cols-sm="2"
                            label-cols-lg="3"
                            label="Referral Type"
                            label-for="type_id"
                            :invalid-feedback="errors[0]"
                            :state="errors[0] ? false : null">
                            <b-form-select id="type_id" name="type_id" plain v-model="form.type_id" @change="onReferralTypeSelected">
                                <b-form-select-option disabled :value="null">* Select Referral Type</b-form-select-option>
                                <b-form-select-option v-for="referral_type in referral_types" :key="referral_type.id" :value="referral_type.id">{{ referral_type.name }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                        </validation-provider>

                        <b-form-group>
                              <b-button :disabled="!canSelectTests" block type="button" variant="primary" @click="showLabReferralModal"><i class="fa-solid fa-solid fa-plus"></i> Add Tests
                              </b-button>
                        </b-form-group>

                        <b-form-group v-show="noTestAvailable">
                            <span class="text-center text-danger">No Tests available for this referral type.</span>
                        </b-form-group>

                        <b-form-group>
                            <b-list-group flush>
                                <b-list-group-item class="flex-column align-items-start" v-for="test in selectedList" :key="`test-${test.id}`">
                                <div class="d-flex w-100 justify-content-between">
                                    <div>
                                        <i class="mr-2 fa-solid fa-microscope"></i> {{test.parent_name}}: {{test.name}}
                                    </div>
                                    <div class="d-flex">

                                        <b-link class="ml-5" @click="removeSelectedbyId(test.id)">
                                            <i class="fa-solid fa-trash-can align-middle"></i>
                                        </b-link>
                                    </div>
                                </div>
                                </b-list-group-item>
                            </b-list-group>
                        </b-form-group>

                        <b-button :disabled="!hasReferrals" class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
                        <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>

                        </b-form>
                        </validation-observer>
                    </b-card>
                </b-collapse>
                </b-card-footer>
            </b-card-body>
        </b-overlay>
        </b-collapse>
    </b-card>
</template>
<script>
import Vue from 'vue'

import ComponentExtension from '../ComponentExtension'
import { mapGetters, mapState, mapActions } from "vuex"

import TestList from './TestList'
import UploadResultDocument from './UploadResultDocument'

import LabReferralModal from './LabReferralModal'
import ChartModal from './ChartModal'
import _ from 'lodash'

export default ComponentExtension.extend({
    name: "lab-referrals",
    components: {
        TestList,
        UploadResultDocument
    },
    data() {
        return {
            addNew: false,
            form: null,
            fields: [
                { key: 'created', label: 'Date/Time', class: 'align-middle', formatter: (value) => this.toLocaleDate(value) },
                { key: 'type.name', label: 'Type', class: 'align-middle' },
                { key: 'priority.name', label: 'Priority', class: 'align-middle' },
                
                { key: "actions", label: "", class: "align-middle text-right" },
            ],

            currentPage: 1,
            perPage: 5,
            sortedBy: 'created',
            sortedDesc: true,

            query: "",

            isLocked: true,
            isDirty: false,
            hasErrors: false,

            confirm: false,
            selected: null,

            filtered: []
        }
    },
    computed: {
        ...mapGetters('lab', ['getPatientLabReferrals']),
        items() {
            return _.cloneDeep(this.getPatientLabReferrals())
        },
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        },
        ...mapState('catalogue', {
            referral_types: state => state.referral_types,
            referral_priorities: state => state.referral_priorities,
            referral_tests: state => state.referral_tests,
        }),
        canSelectTests() {
            return this.form.type_id && this.form.priority_id && this.filtered.length > 0
        },
        noTestAvailable() {
            return this.form.type_id && this.form.priority_id && this.filtered.length === 0
        },
        hasReferrals() {
            return this.form.lab_referral_tests.length > 0
        },
        selectedList() {
            if(this.hasReferrals) {
                return this.form.lab_referral_tests.map(referral => this.referral_tests.find(item => item.id === referral.test))
            }

            return [] 
        },

        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.dirty(this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.saved(this.$vnode.key)
                }
            }
        },
        catalogue_defaults: {
            immediate: true,
            handler(val) {
                this.newFormWithDefaults(val)
            }
        },
        addNew(val) {
            if (!val) {
                this.reset()
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('lab', ['getLabReferralsByPatientId', 'createLabReferral', 'deleteLabReferral', 'getLabChartDataByPatientId']),

        onRemoveItem(item) {
            this.confirm = true
            this.processing = false
            this.selected = item
        },
        onConfirmed(item) {

            this.processing = true

            this.deleteLabReferral(item.id).then(() => {
                this.notify("Patient allergy removed", "Patient allergy was removed successfully")
                this.confirm = false
                this.processing = false
                this.selected = null

            })
            .catch(errors => {
                this.notify("Error", "Something went wrong", 'danger')
                this.confirm = false
                this.processing = false
                this.selected = null
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
            this.processing = false
            this.selected = null
        },
        openDocument(referral) {
            if(referral.document_extension === ".pdf") {
                window.open(referral.document_url, '_blank')
            }else{
                this.$viewerApi({
                  images: [referral.document_url],
                })
            }
        },
        filterItems(id) {
            return new Promise((resolve, reject) => {
                try {
                    const type = this.referral_types.find(type => type.id === id)
                    const rootParent = this.referral_tests.find(parent => parent.code === type.code)

                    if (rootParent) {
                        const parents = this.referral_tests.filter(parent => parent.parent_id === rootParent.id)
                        // const children = this.referral_tests.filter(child => parents.some(parent => child.parent_id === parent.id))

                        // Find children based on parent IDs
                        const filtered = parents.map(parent => {
                            const children = this.referral_tests.filter(child => child.parent_id === parent.id)
                            return { parent, children }
                        })

                        resolve(filtered)
                    }

                    resolve([])

                } catch (error) {
                    reject(error)
                }
            })
        },
        async onReferralTypeSelected(id) {
            if (id) {
                try {
                    this.filtered = await this.filterItems(id)
                } catch (error) {
                    // console.error(error)
                }
            }
        },
        removeSelectedbyId(id) {
            const form = this.form.data()
            const newSelected = form.lab_referral_tests.filter(selected => selected.test !== id)
            this.form.populate({lab_referral_tests: newSelected})

        },
        showLabReferralModal(field) {
            var ComponentClass = Vue.extend(LabReferralModal)

            const form = this.form.data()
            const selectedTests = form.lab_referral_tests.map(referral => referral.test)
            var instance = new ComponentClass({ parent: this, propsData: { tests: this.filtered, selectedTests: selectedTests } })

            instance.$on("hidden", () => {
                instance.$destroy()
            })

            instance.$on("selected", (tests) => {
                let referralsArray = []
                tests.forEach(referral => {
                    referralsArray.push({test: referral, consistency_token: null})
                })

                this.form.populate({lab_referral_tests: referralsArray})
            })

            instance.$mount()
        },
        async showChartModal() {
            var ComponentClass = Vue.extend(ChartModal)

            const chartData = await this.getLabChartDataByPatientId()
            var instance = new ComponentClass({ parent: this, propsData: { items: chartData } })

            instance.$on("hidden", () => {
                instance.$destroy()
            })

            instance.$mount()
        },
        onFormSubmit() {
            this.createLabReferral(this.form.data()).then(() => {
                this.reset()
                this.notify("Lab referral created", "Lab referral was created successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer.setErrors(errors.response.data)
                }
            })
        },
        newFormWithDefaults(defaults) {
            this.form = Form.create({
                // type_id: defaults.referral_type,
                type_id: null,
                priority_id: defaults.referral_priority,
                lab_referral_tests: [],
                consistency_token: null
            })
        },
        reset() {
            this.newFormWithDefaults(this.catalogue_defaults)
            this.$refs.observer.reset()

            this.$nextTick(() => {
                this.query = ""
                this.hasErrors = false
                this.addNew = false
                this.saved(this.$vnode.key)
            })
        },
        toggleDetails(row) {
            if (row.detailsShowing) {
                this.getLabReferralsByPatientId()
            }

            row.toggleDetails()
        }
    },
    async mounted() {
        await this.getCatalogueItem('referral_types')
        await this.getCatalogueItem('referral_priorities')
        await this.getCatalogueItem('referral_tests')
        await this.getLabReferralsByPatientId()

    }
})
</script>