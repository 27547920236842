<template>
    <b-form-group
        :label-for="id"
        :invalid-feedback="errors"
        :state="errors ? false : null">
        <template slot="label">
            {{ label }} <i  v-b-tooltip.hover.right :title="iconTooltip" class="fa-solid fa-lg" :class="{ 'fa-circle-o fa-beat-fade text-danger': isListening, 'fa-circle-microphone text-primary': !isListening }" @click="toggleListening"></i>
        </template>

        <b-form-textarea v-bind="$attrs" :id="id" :class="classes" :state="state" v-model="transcript" @input="updateValue()" />

        <slot></slot>

    </b-form-group>
</template>

<script>
export default {
    props: ['id', 'label', 'errors', 'value', 'state', 'classes'],
    inheritAttrs: false,
    data() {
        return {
            isListening: false,
            transcript: '',
        }
    },
    computed: {
        iconTooltip() {
            return this.isListening ? 'Click to stop voice input' : 'Click to start voice input (BETA)'
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.transcript = val
            }
        }
    },
    methods: {
        toggleListening() {
            this.isListening = !this.isListening
            if (this.isListening) {
                this.$speechRecognition.initialize(this.speechLang) // Initialize with language setting
                this.$speechRecognition.start(this.handleResult, this.onEnd)
            } else {
                this.$speechRecognition.stop()
            }
        },
        handleResult(transcript) {
            this.transcript = transcript
        },
        onEnd() {
            this.isListening = false
        },
        updateValue() {
            this.$emit('input', this.transcript)
        },
    },
    beforeDestroy() {
        if (this.isListening) {
            this.$speechRecognition.stop()
        }
    }
}
</script>
