<template>
	<b-modal size="lg" title="Episodes" v-model="showModal" no-close-on-esc no-close-on-backdrop body-class="p-0" modal-class="modal-warning" ok-variant="warning" :ok-only="true" ok-title="Dismiss" @hidden="onHidden">
		<b-container fluid>
	        <b-overlay :show="canMerge" no-wrap @shown="onShown">
	            <template #overlay>
	                <div v-if="processing" class="d-flex align-items-center">
	                    <strong>Merging...</strong>
	                    <b-spinner class="ml-auto"></b-spinner>
	                </div>
	                <div v-else ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
	                    <p class="text-center">
	                        <i class="fa-solid fa-merge text-danger"></i> <strong class="text-danger">MERGE EPISODES </strong>
	                    </p>
	                    <p class="text-center"><i class="fa-solid fa-info-circle text-danger"></i> <strong class="font-italic text-muted">NOTE: this cannot be undone!</strong></p>
	                    <div class="text-center">
	                        <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
	                        <b-button variant="outline-success" @click="onConfirmed(selected)">Confirm</b-button>
	                    </div>
	                </div>
	            </template>
	        </b-overlay>
			<b-overlay :show="$store.getters['loading/isLoading']('episodes')" rounded="sm">
				<b-table-simple
					ref="table"
					responsive
					show-empty
					borderless
					striped>
					<b-thead>
						<b-tr>
							<b-th class="align-middle text-center" v-b-tooltip.noninteractive.hover.rightbottom title="Drag handle to order."><i class="fa-solid fa-arrow-down-arrow-up"></i></b-th>
							<b-th class="align-middle text-center"><i class="fa-solid fa-merge"></i></b-th>
							<b-th class="align-middle text-center"><i class="fa-solid fa-bookmark"></i></b-th>
							<b-th class="align-middle text-center">#</b-th>
							<b-th>Started</b-th>
							<b-th>Closed</b-th>
							<b-th>Name</b-th>
							<b-th class="align-middle text-center">Status</b-th>
							<b-th class="align-middle text-center">ICPC</b-th>
							<b-th></b-th>
						</b-tr>
					</b-thead>
					<draggable v-model="list" tag="b-tbody" handle=".handle" v-bind="dragOptions" @change="onDragChange">
						<b-tr v-for="episode in list" :key="episode.id" :variant="episode.active ? '' : 'danger'">
							<b-td class="handle align-middle text-center"><i class="fa-solid fa-grip-dots-vertical"></i></b-td>
							<b-td class="align-middle text-center">
								<b-form-checkbox v-show="episode.active" v-model="selected" :value="episode.id"></b-form-checkbox>
							</b-td>
							<b-td class="align-middle text-center">
								<b-button size="sm" variant="link" @click="onBookmark(episode)">
								<i class="fa" :class="episode.bookmarked ? 'fa-solid fa-bookmark' : 'fa-light fa-bookmark'"></i>
								</b-button>
							</b-td>
							<b-td>{{ episode.id }}</b-td>
							<b-td>{{ toLocaleDate(episode.start) }}</b-td>
							<b-td :class="{ 'small font-italic text-muted': !episode.end }">{{ episode.end ? toLocaleDate(episode.end) : 'n/a' }}</b-td>
							<b-td>{{ episode.name }}</b-td>
							<b-td class="align-middle text-center" :class="episode.active ? 'text-success' : 'text-danger'">{{ episode.active ? 'Active' : 'Inactive' }}</b-td>
							<b-td :class="{ 'small font-italic text-muted': !episode.icpc }" class="align-middle text-center" :variant="episode.icpc ? 'success' : null">{{ episode.icpc ? episode.icpc.code : 'n/a' }}</b-td>
							<b-td class="align-middle text-center">
								<template v-if="episode.id === current.id">
									<b-button disabled size="sm" variant="light">Selected</b-button>
								</template>
								<template v-else>
									<b-button v-if="episode.active" size="sm" variant="primary" @click="onSelected(episode)">Set</b-button>
									<b-button v-else size="sm" variant="warning" @click="onActivate(episode)">Activate</b-button>
								</template>
							</b-td>
						</b-tr>
					</draggable>
					<b-tr v-if="noEpisodes" class="b-table-empty-row">
						<b-td colspan="8" class="text-center my-2">No Episodes</b-td>
					</b-tr> 
				</b-table-simple>
			</b-overlay>
		</b-container>
		<template #modal-footer="{ ok, cancel, hide }">
			<b-button v-if="!newEpisode" variant="success" @click="newEpisode = true">New Episode</b-button>
			<b-button v-else variant="warning" @click="newEpisode = false">Undo</b-button>
			<template v-if="newEpisode">
				<validation-observer ref="observer" v-slot="{ passes }">
					<b-form  inline @submit.prevent="passes(onFormSubmit)" novalidate>
						<validation-provider vid="name" v-slot="{ errors }">
							<b-form-input :state="errors[0] ? false : null" id="name" name="name" v-model="form.name" placeholder="Add episode name..." />
						</validation-provider>
						<b-button type="submit" class="ml-1" variant="success">Create</b-button>
					</b-form>
				</validation-observer>
			</template>
			<b-button class="ml-auto" variant="warning" @click="cancel()">Dismiss</b-button>
		</template>
	</b-modal>
</template>
<script>
import Vue from "vue"
import ModalExtension from "@/core/components/modal/ModalExtension"
import { mapGetters, mapState, mapActions } from "vuex"
import draggable from "vuedraggable"

import ICPCModal from '../catalogue/ICPCModal'

export default ModalExtension.extend({
	name: 'episode-modal',
	props: ['current'],
	components: {
		draggable
	},
	data() {
		return {
			list: [],
			isBusy: false,
			form: new Form({
				name: "",
				consistency_token: null
			}),


            isDirty: false,
            hasErrors: false,

            processing: false,
			selected: [],

			newEpisode: false
		}
	},
	computed: {
		...mapGetters('episodes', ['getEpisodes']),
		episodes() {
			return this.getEpisodes()
		},
		dragOptions() {
			return {
				swap: true,
				animation: 200,
				disabled: false,
				ghostClass: "ghost"
			}
		},
		noEpisodes() {
			return this.list.length == 0
		},
		canMerge() {
			return this.selected.length === 2
		}
	},
	watch: {
		episodes: {
			immediate: true,
			handler(val) {
				this.list = val
			}
		},
		form: {
			deep: true,
			handler(val) {
				this.hasErrors = !this.formValid
			}
		}
	},
	methods: {
		...mapActions('episodes', ['bookmarkEpisode', 'sortEpisodes', 'activateEpisode', 'createNewEpisode', 'mergeEpisodes']),
        async onConfirmed(selected) {

            this.processing = true

            let mergeObjects = []
            selected.forEach((id, index) => {
				mergeObjects.push({id: id, leading: index == 0 ? true : false})
			})

            await this.mergeEpisodes(mergeObjects).then(() => {
                this.processing = false
                this.selected = []

            })
            .catch((errors) => {
                this.processing = false
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.processing = false
            this.selected = []
        },
		onSelected(episode) {
			this.$emit('selected', episode)
			this.hide()
		},
		async onActivate(episode) {
			await this.activateEpisode(episode.id)
		},
		async onDragChange() {
			let updateSort = []
			this.list.forEach((element, index) => {
				updateSort.push({id: element.id, sort_order: index})
			})
			
			await this.sortEpisodes(updateSort)
		},
		async onBookmark(episode) {
			await this.bookmarkEpisode({id: episode.id, bookmarked: !episode.bookmarked })
		},
		onFormSubmit() {
			this.createNewEpisode(this.form.data()).then(() => {
				this.reset()
			})
            .catch(errors => {
                if (errors.response) {
	                this.$refs.observer.setErrors(errors.response.data)
	                this.hasErrors = true
            	}
            })
		},
		reset() {
			this.form.reset()
			this.$refs.observer.reset()

			this.$nextTick(() => {
				this.newEpisode = false
				this.hasErrors = false
			})
		}
	}
})
</script>
<style scoped>
	.ghost {
		opacity: 0.5;
		background: #c8ebfb;
	}

	.handle {
		cursor: move;
	}
</style>