<template>
<b-tab lazy title-item-class="mr-1" :title-link-class="{'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Chronic Prescriptions
    </template>

    <div class="d-flex mb-3" v-if="checkUserPermission('prescription', 'can_add')">
        <b-button :disabled="!current_consult" class="ml-auto" @click="addNew = !addNew" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
    </div>

    <b-collapse id="add" v-model="addNew" variant="primary">
        <b-card>
            <validation-observer ref="observer" v-slot="{ passes }">
            <b-form @submit.prevent="passes(onFormSubmit)" novalidate>

                <validation-provider vid="non_field_errors" v-slot="{ errors }">
                  <b-form-invalid-feedback :state="errors[0] ? false : null">
                    <h6 class="d-block p-3 bg-danger ">{{ errors[0] }}</h6>
                  </b-form-invalid-feedback>
                </validation-provider>
                
                <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Is Chronic Prescription">
                    <b-input-group class="mb-2">
                        <b-input-group-prepend is-text>
                            <b-form-checkbox v-model="form.is_chronic" name="is_chronic"></b-form-checkbox>
                        </b-input-group-prepend>
                        <b-input-group-prepend is-text>repeat</b-input-group-prepend>
                        <b-form-input :disabled="disabledRepeat" v-model="form.repeat_prescription"></b-form-input>
                        <b-input-group-prepend is-text>times after</b-input-group-prepend>
                        <b-form-select :disabled="disabledRepeat" id="repeat_date" name="repeat_date" plain v-model="form.repeat_date_id">
                            <b-form-select-option disabled :value="null">Select Repeat Date</b-form-select-option>
                            <b-form-select-option v-for="repeat_date in medication_repeat_dates" :key="repeat_date.id" :value="repeat_date.id">{{ repeat_date.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-input-group>
                </b-form-group>

                <validation-provider vid="medication_id" v-slot="{ errors }">
                    <b-form-group
                        :disabled="hasAlternateMedication"
                        label-cols-sm="2"
                        label-cols-lg="2"
                        label="Medication"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <vue-bootstrap-autocomplete v-model="query" :data="medications" placeholder="Search Medication..." :minMatchingChars="1" :serializer="medicationSerializer" @hit="onHitMedication">
                                <template slot="suggestion" slot-scope="{ data, htmlText }">
                                    <div class="d-flex align-items-center">
                                        <!-- Note: the v-html binding is used, as htmlText contains
                                        the suggestion text highlighted with <strong> tags -->
                                        <span class="ml-1" v-html="htmlText"></span>
                                        <span class="ml-auto">{{ data.code }}</span>
                                    </div>
                                </template>
                        </vue-bootstrap-autocomplete>
                    </b-form-group>
                </validation-provider>
                <validation-provider vid="other" v-slot="{ errors }">
                    <b-form-group
                        label-cols-sm="2"
                        label-cols-lg="2"
                        label="Other"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <b-form-checkbox v-b-tooltip.hover title="" v-model="hasAlternateMedication" @change="onAlternateMedicationChange">
                            </b-form-checkbox>
                            </b-input-group-prepend>
                            <b-form-input id="other" :disabled="!hasAlternateMedication" name="other" placeholder="Other medication" v-model="form.other" @input="onOtherMedication"/>
                        </b-input-group>
                    </b-form-group>
                </validation-provider>
                <validation-provider name="Strength" vid="strength" rules="measurement" v-slot="{ errors }">
                    <b-form-group
                        label-cols-sm="2"
                        label-cols-lg="2"
                        label="Strength"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-input-group>
                            <b-form-input type="number" id="strength" v-comma2dot name="strength" placeholder=".00" v-model="form.strength" />
                            <template #append>
                                <b-form-select id="unit_id" name="unit_id" class="squared-left" plain v-model="form.unit_id">
                                 <b-form-select-option disabled :value="null">Select Unit</b-form-select-option>
                                <b-form-select-option v-for="unit in units" :key="unit.id" :value="unit.id">{{ unit.name }}</b-form-select-option>
                                </b-form-select>
                            </template>
                        </b-input-group>
                         <validation-provider vid="unit_id" v-slot="{ errors }">
                            <b-form-invalid-feedback :state="errors[0] ? false : null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                        </validation-provider>
                    </b-form-group>
                </validation-provider>

                <validation-provider name="Dosage" vid="frequency_usage" rules="double:2" v-slot="{ errors }">
                <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Dosage"
                    description="example: 3 times per day 2 tablets"
                    :invalid-feedback="errors[0]"
                    :state="errors[0] ? false : null">
                             <b-input-group>
                                <b-form-input type="number" id="frequency_usage" name="frequency_usage" v-model="form.frequency_usage" placeholder="Frequency usage..." autocomplete="off" />
                                 <b-input-group-prepend is-text>
                                    times per
                                </b-input-group-prepend>
                                <!-- <b-form-input value="times per" readonly autocomplete="off" /> -->
                                <b-form-select id="frequency_unit_id" name="frequency_unit_id" plain v-model="form.frequency_unit_id" @change="onFrequencyUnitSelected">
                                    <b-form-select-option disabled :value="null">Select Frequency Unit</b-form-select-option>
                                    <b-form-select-option v-for="frequency_unit in frequency_units" :key="frequency_unit.id" :value="frequency_unit.id">{{ frequency_unit.name }}
                                    </b-form-select-option>
                                </b-form-select>
                            <b-form-input type="number" id="dosage_amount" v-comma2dot name="dosage_amount" v-model="form.dosage_amount" placeholder="Dosage amount..." autocomplete="off" />
                            <b-form-select id="dosage_form_id" name="dosage_form_id" plain v-model="form.dosage_form_id">
                                <b-form-select-option disabled :value="null">Select Dosage form</b-form-select-option>
                                <b-form-select-option v-for="prescription_dosage_form in prescription_dosage_forms" :key="prescription_dosage_form.id" :value="prescription_dosage_form.id">{{ prescription_dosage_form.name }}
                                </b-form-select-option>
                            </b-form-select>
                            </b-input-group>
                            <validation-provider vid="frequency_unit_id" v-slot="{ errors }">
                                <b-form-invalid-feedback :state="errors[0] ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                            <validation-provider vid="dosage_amount" v-slot="{ errors }">
                                <b-form-invalid-feedback :state="errors[0] ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                            <validation-provider vid="dosage_form_id" v-slot="{ errors }">
                                <b-form-invalid-feedback :state="errors[0] ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                </b-form-group>
                </validation-provider>

                <validation-provider vid="delivery_form_id" v-slot="{ errors }">
                    <b-form-group
                        label-cols-sm="2"
                        label-cols-lg="2"
                        label="Administration"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">

                        <b-form-select id="delivery_form_id" name="delivery_form_id" plain v-model="form.delivery_form_id">
                            <b-form-select-option disabled :value="null">Select Delivery Form</b-form-select-option>
                            <b-form-select-option v-for="delivery_form in delivery_forms" :key="delivery_form.id" :value="delivery_form.id">{{ delivery_form.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </validation-provider>

                <validation-provider vid="amount_of_time" v-slot="{ errors }">
                    <b-form-group
                        label-cols-sm="2"
                        label-cols-lg="2"
                        label="Period"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                            <b-input-group :append="frequencyPeriod">
                                <b-form-input type="number" id="amount_of_time" name="amount_of_time" v-model="form.amount_of_time" placeholder="Amount of time" autocomplete="off" />
                                 <b-input-group-prepend is-text>
                                    Total
                                </b-input-group-prepend>
                                <b-form-input readonly id="total_amount" :value="calculatedTotal" autocomplete="off" />
                            </b-input-group>
                    </b-form-group>
                </validation-provider>

                <b-form-group
                    label="Administration Instructions"
                    label-for="instructions">
                        <b-form-textarea id="instructions" v-capitalize v-model="form.instructions" placeholder="Add administration instructions..." rows="5"></b-form-textarea>
                </b-form-group>
            
            <b-button class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
            <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>

            </b-form>
            </validation-observer>
        </b-card>
    </b-collapse>

    <b-overlay :show="confirm" no-wrap @shown="onShown">
        <template #overlay>
            <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                <p class="text-center">
                    <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE PRESCRIPTION INFO ON {{ toLocaleDate(selected?.created) }} </strong>
                </p>
                <div class="text-center">
                    <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                    <b-button variant="outline-success" @click="onConfirmed">Confirm</b-button>
                </div>
            </div>
        </template>
    </b-overlay>
    <b-overlay 
        :show="$store.getters['loading/isLoading']('prescriptions')"
        rounded="sm"
        variant="light"
        :opacity="0.80"
        blur="2px">
        <b-table
            ref="table"
            class="m-0"
            responsive
            stacked="sm"
            show-empty
            striped
            hover
            fixed
            small
            primary-key="id"
            :current-page="currentPage"
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :busy.sync="$store.getters['loading/isLoading']('prescriptions')"
            :no-sort-reset="true"
            :sort-by.sync="sortedBy"
            :sort-desc.sync="sortedDesc"
            :no-border-collapse="false">

            <template v-slot:cell(full_medication)="data">
                <b-button v-if="data.item.is_repeated" disabled size="sm" variant="secondary" v-b-tooltip.hover title="Repeated Prescription"><i class="fa-solid fa-repeat"></i></b-button> {{ data.value }}
            </template>

            <template v-slot:cell(stoppage_date_time)="data">
                <template v-if="data.value">
                    <div>{{toLocaleDate(data.value)}}</div>
                    <div class="small">
                        {{data.item.stoppage_reason}}
                    </div>                            
                </template>
                <template v-else>
                    <span class="small font-italic text-muted">n/a</span>
                </template>
            </template>
            
            <template v-slot:cell(total_amount)="data">
                {{ data.value }} units
            </template>

            <template #row-details="row">
                <b-card>
                    <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="3"
                    label="Stoppage Reason"
                    label-for="stoppage_reason">
                        <b-form-textarea id="stoppage_reason" v-capitalize v-model="form.stoppage_reason" placeholder="Add reason for stop medication" rows="5"></b-form-textarea>
                </b-form-group>
                <b-button class="mr-1" type="button" variant="success" @click="onStopMedication"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
                </b-card>
            </template>

            <template v-slot:cell(actions)="row">
                <b-button-group size="sm">
                    <b-button v-if="checkUserPermission('prescription', 'can_print') && row.item.printable" variant="secondary" v-b-tooltip.hover title="Print Prescription" @click="printRow(row)"><i class="fa-solid fa-print"></i></b-button>
                    <template v-if="!row.item.is_repeated">
                        <b-button v-if="checkUserPermission('prescription', 'can_change') && !row.item.medication_stopped" variant="warning" @click="onStopItem(row)" v-b-tooltip.hover title="Stop medication"><i class="fa-solid fa-stop"></i></b-button>
                        <b-button  v-if="checkUserPermission('prescription', 'can_change') && row.item.editable" variant="primary" v-b-tooltip.hover title="Edit prescription" @click="onEditItem(row)"><i class="fa-solid fa-pen"></i></b-button>
                        <b-button  v-if="checkUserPermission('prescription', 'can_delete')" variant="danger" @click="onRemoveItem(row)" v-b-tooltip.hover title="Remove prescription"><i class="fa-light fa-xmark"></i></b-button>                     
                    </template>
                </b-button-group>                    
            </template>

            <div slot="table-busy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>

            <template v-slot:empty="scope">
                <div class="small text-muted text-center my-2">
                    <span>n/a</span>
                </div>
            </template>

            <template v-slot:custom-foot="scope">
                <b-tr class="border-top">
                    <b-td colspan="4" class="pt-3 align-middle" variant="light" style="background: #fff;">
                        Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                    </b-td>
                    <b-td colspan="2" class="pt-3 text-right align-middle" variant="light" style="background: #fff;">
                        <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                    </b-td>
                </b-tr>
            </template>
        </b-table>
    </b-overlay>
</b-tab>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex"
import TabExtension from "../TabExtension"

import _ from 'lodash'

export default TabExtension.extend({
    name: "chronic-prescriptions",
    data() {
        return {
            addNew: false,
            form: new Form({
                medication_id: null,
                is_chronic: true,
                other: "",
                strength: null,
                unit_id: null,
                frequency_usage: null,
                frequency_unit_id: null,
                dosage_amount: null,
                dosage_form_id: null,
                amount_of_time: null,
                delivery_form_id: null,
                instructions: "",
                stoppage_reason: "",
                repeat_prescription: 0,
                repeat_date_id: null,
                consistency_token: null
            }),

            fields: [
                { key: 'created', label: 'Date/Time', class: 'small align-middle' , formatter: (value) => this.toLocaleDate(value) },
                { key: 'full_medication', label: 'Medication', class: 'small align-middle' },
                { key: 'directions', label: 'Directions', class: 'small align-middle' },
                { key: 'stoppage_date_time', label: 'Stoppage Date/Time', class: 'small align-middle text-center' },
                { key: 'total_amount', label: 'Total', class: 'small align-middle text-center' },
                { key: "actions", label: "", class: "small align-middle text-right" },
            ],

            currentPage: 1,
            perPage: 5,
            sortedBy: 'created',
            sortedDesc: true,

            query: "",

            isLocked: true,
            isDirty: false,
            hasErrors: false,

            confirm: false,
            processing: false,
            selected: null,

            frequencyPeriod: "",
            hasAlternateMedication: false
        }
    },
    computed: {
        ...mapGetters('prescriptions', ['getPatientChronicPrescriptions']),
        items() {
            return _.cloneDeep(this.getPatientChronicPrescriptions())
        },
        ...mapState('catalogue', {
            medications: state => state.medications,
            units: state => state.units,
            frequency_units: state => state.frequency_units,
            prescription_dosage_forms: state => state.prescription_dosage_forms,
            delivery_forms: state => state.delivery_forms,
            side_effects_statuses: state => state.side_effects_statuses,
            medication_repeat_dates: state => state.medication_repeat_dates,
            severities: state => state.severities
        }),
        ...mapState('patients', {
            current_consult: state => state.current.current_consult,
        }),
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
            
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        },

        calculatedTotal() {
            return this.form.frequency_usage * this.form.dosage_amount * (this.form.amount_of_time ? this.form.amount_of_time : 1)
        },

        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        },
        disabledRepeat() {
            return this.form.is_chronic == false
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.$emit('dirty', this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.$emit('saved', this.$vnode.key)
                }
            }
        },
        addNew(val) {
            if (!val) {
                this.reset()
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('prescriptions', ['getPrescriptionsByPatientId', 'createPrescriptionForCurrentPatient', 'deletePatientPrescription', 'editPatientPrescription', 'updatePatientPrescription', 'prescriptionPrintableById']),
        ...mapActions('health', ['checkForAllergy']),
        onStopItem(row) {
            if (!row.detailsShowing) {
                this.selected = row.item
                this.form.populate(row.item)
            }else{
                this.reset()
            }

            row.toggleDetails()
        },
        onEditItem(row) {
            this.selected = row.item
            this.form.populate(row.item)
            if (row.item.medication) {
                this.query = row.item.medication.name
            }else{
                this.hasAlternateMedication = true
            }

            this.addNew = !this.addNew
        },
        onRemoveItem(row) {
            this.confirm = true
            this.processing = false
            this.selected = row.item
        },
        onConfirmed() {
            if (this.selected) {
                this.processing = true
                this.deletePatientPrescription(this.selected.id).then(() => {
                    this.notify("Patient prescription removed", "Patient prescription was removed successfully")
                    this.confirm = false
                    this.processing = false
                    this.selected = null

                })
                .catch(errors => {
                    this.notify("Error", "Something went wrong", 'danger')
                    this.confirm = false
                    this.processing = false
                    this.selected = null
                })
            }
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
            this.processing = false
            this.selected = null
        },
        onAlternateMedicationChange(value) {
            // clear date form data
            this.form.populate({medication_id: null, other: ""})
            this.query = ""
        },
        onFrequencyUnitSelected(id) {
            if (id) {
                const fu = this.frequency_units.find(item => item.id ===id)
                this.frequencyPeriod = fu.name
            }else{
                this.frequencyPeriod = ""
            }
        },
        medicationSerializer(item) {
            return item.name
        },

        async onOtherMedication(str) {
            const check = await this.checkForAllergy({ allergy: str })
            if (check) {
                this.notify("Patient Warning", "Patient is allergic for this medication.", "warning")
                this.$refs.observer.setErrors({"other": ["Patient is allergic to this medication."]})
            }else{
                this.$refs.observer.reset()
            }
        },

        async onHitMedication(item) {
            const check = await this.checkForAllergy({ allergy: item.name })
            if (check) {
                this.notify("Patient Warning", "Patient is allergic for this medication.", "warning")
                this.$refs.observer.setErrors({"medication_id": ["Patient is allergic to this medication."]})
            }else{
                this.$refs.observer.reset()
            }
            
            this.form.populate({medication_id: item.id})
        },
        
        onFormSubmit() {
            if (this.selected) {
                this.updatePatientPrescription({id: this.selected.id, data: this.form.data() }).then(() => {
                    this.reset()
                    this.notify("Patient prescription updated", "Patient prescription was updated successfully")
                })
                .catch(errors => {
                    if (errors.response) {
                        this.$refs.observer.setErrors(errors.response.data)
                    }
                })
            } else {
                this.createPrescriptionForCurrentPatient(this.form.data()).then(() => {
                    this.reset()
                    this.notify("Patient prescription created", "Patient prescription was created successfully")
                })
                .catch(errors => {
                    if (errors.response) {
                        this.$refs.observer.setErrors(errors.response.data)
                    }
                })
            }
        },
        onStopMedication() {
            if (this.selected) {
                this.updatePatientPrescription({id: this.selected.id, data: this.form.data() }).then(() => {
                    this.reset()
                    this.notify("Stop medication", "Patient medication was stopped successfully")
                })
                .catch(errors => {
                    if (errors.response) {
                        this.$refs.observer.setErrors(errors.response.data)
                    }
                })
            }
        },
        reset() {
            this.form.reset()
            this.$refs.observer.reset()

            this.$nextTick(() => {
                this.hasErrors = false
                this.addNew = false
                this.$emit('saved', this.$vnode.key)
            })
        },
        async printRow(row) {
            const prescription = await this.prescriptionPrintableById(row.item.id)
            printJS({
                printable: prescription,
                type: 'pdf',
                base64: true
            })
        }
    },
    async mounted() {
        await this.getCatalogueItem('medications')
        await this.getCatalogueItem('units')
        await this.getCatalogueItem('frequency_units')
        await this.getCatalogueItem('prescription_dosage_forms')
        await this.getCatalogueItem('delivery_forms')
        await this.getCatalogueItem('side_effects_statuses')
        await this.getCatalogueItem('medication_repeat_dates')
        await this.getCatalogueItem('severities')

        await this.getPrescriptionsByPatientId()
    }
})
</script>