<template>
<b-tab lazy title-item-class="mr-1" :title-link-class="{'bg-danger': hasErrors, 'bg-warning': isDirty}">
    <template slot="title">
        <i v-if="isDirty || hasErrors" class="fa-solid fa-circle-exclamation" :class="{ 'fa-circle-exclamation text-white': hasErrors, 'fa-cloud-arrow-up text-black': isDirty}"></i> Measurements
    </template>

    <div class="d-flex mb-3" v-if="checkUserPermission('measurements', 'can_add')">
        <b-button class="ml-auto" @click="addNew = !addNew" :variant="addNew ? 'danger' : 'primary'"><i :class="[addNew ? 'fa-solid fa-ban' : 'fa-solid fa-plus']"></i> {{ addNew ? 'Cancel' : 'Add' }}</b-button>
    </div>

    <b-collapse id="add" v-model="addNew" variant="primary">
        <b-card>
            <validation-observer ref="observer" v-slot="{ passes }">
            <b-form @submit.prevent="passes(onFormSubmit)" novalidate>

                <validation-provider vid="non_field_errors" v-slot="{ errors }">
                  <b-form-invalid-feedback :state="errors[0] ? false : null">
                    <h6 class="d-block p-3 bg-danger ">{{ errors[0] }}</h6>
                  </b-form-invalid-feedback>
                </validation-provider>

                <validation-provider name="Height" vid="height" rules="measurement" v-slot="{ errors }">
                    <b-form-group
                        label-cols-sm="2"
                        label-cols-lg="2"
                        label="Height"
                        label-for="height"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                         <b-input-group>
                            <b-form-input id="height" v-comma2dot name="height" placeholder=".00" v-model="form.height"  />
                            <template #append>
                                <b-form-select id="height_unit" name="height_unit" class="squared-left" plain v-model="form.height_unit">
                                    <b-form-select-option :value="null">Height Unit</b-form-select-option>
                                    <b-form-select-option value="ft">ft</b-form-select-option>
                                    <b-form-select-option value="inch">inch</b-form-select-option>
                                    <b-form-select-option value="m">m</b-form-select-option>
                                    <b-form-select-option value="cm">cm</b-form-select-option>
                                </b-form-select>
                            </template>
                        </b-input-group>
                         <validation-provider vid="height_unit" v-slot="{ errors }">
                            <b-form-invalid-feedback :state="errors[0] ? false : null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                        </validation-provider>
                        </b-input-group>
                    </b-form-group>
                </validation-provider>
                <validation-provider name="Weight" vid="weight" rules="measurement" v-slot="{ errors }">
                    <b-form-group
                        label-cols-sm="2"
                        label-cols-lg="2"
                        label="Weight"
                        label-for="weight"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-input-group>
                            <b-form-input id="weight" v-comma2dot name="weight" placeholder=".00" v-model="form.weight" />
                            <template #append>
                                <b-form-select id="weight_unit" name="weight_unit" class="squared-left" plain v-model="form.weight_unit">
                                    <b-form-select-option :value="null">Weight Unit</b-form-select-option>
                                    <b-form-select-option value="g">g</b-form-select-option>
                                    <b-form-select-option value="kg">kg</b-form-select-option>
                                    <b-form-select-option value="lb">lb</b-form-select-option>
                                </b-form-select>
                            </template>
                        </b-input-group>
                         <validation-provider vid="weight_unit" v-slot="{ errors }">
                            <b-form-invalid-feedback :state="errors[0] ? false : null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                        </validation-provider>
                    </b-form-group>
                </validation-provider>
                <validation-provider name="Waist circumference" vid="waist" rules="measurement" v-slot="{ errors }">
                    <b-form-group
                        label-cols-sm="2"
                        label-cols-lg="2"
                        label="Waist circumference"
                        label-for="waist"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-input-group append="cm">
                            <b-form-input id="waist" v-comma2dot name="waist" placeholder=".00" v-model="form.waist" />
                        </b-input-group>
                    </b-form-group>
                </validation-provider>
                <validation-provider name="Hip" vid="hip" rules="measurement" v-slot="{ errors }">
                    <b-form-group
                        label-cols-sm="2"
                        label-cols-lg="2"
                        label="Hip "
                        label-for="hip"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-input-group append="cm">
                            <b-form-input id="hip" v-comma2dot name="hip" placeholder=".00" v-model="form.hip" />
                        </b-input-group>
                    </b-form-group>
                </validation-provider>
                <validation-provider name="Glucose" vid="glucose" rules="measurement" v-slot="{ errors }">
                    <b-form-group
                        label-cols-sm="2"
                        label-cols-lg="2"
                        label="Glucose"
                        label-for="glucose"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-input-group append="mmol/L">
                            <b-form-input id="glucose" v-comma2dot name="glucose" placeholder=".00" v-model="form.glucose" />
                        </b-input-group>
                    </b-form-group>
                </validation-provider>

            
            <b-button class="mr-1" type="submit" variant="success"><i class="fa-regular fa-circle-dot"></i> Submit</b-button>
            <b-button v-if="isDirty || hasErrors" type="button" variant="danger" @click="reset"><i class="fa-solid fa-ban"></i> Reset</b-button>

            </b-form>
            </validation-observer>
        </b-card>
    </b-collapse>

    <b-overlay :show="confirm" no-wrap @shown="onShown">
        <template #overlay>
            <div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                <p class="text-center">
                    <i class="fa-light fa-xmark text-danger"></i> <strong class="text-danger">DELETE MEASUREMENTS INFO ON {{ toLocaleDate(selected?.created) }} </strong>
                </p>
                <div class="text-center">
                    <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                    <b-button variant="outline-success" @click="onConfirmed(selected)">Confirm</b-button>
                </div>
            </div>
        </template>
    </b-overlay>
    <b-overlay 
        :show="$store.getters['loading/isLoading']('measurements')"
        rounded="sm"
        variant="light"
        :opacity="0.80"
        blur="2px">
        <b-table
            ref="table"
            class="m-0"
            responsive
            stacked="sm"
            show-empty
            striped
            hover
            fixed
            small
            primary-key="id"
            :current-page="currentPage"
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :busy.sync="$store.getters['loading/isLoading']('measurements')"
            :no-sort-reset="true"
            :sort-by.sync="sortedBy"
            :sort-desc.sync="sortedDesc"
            :no-border-collapse="false">
            <template v-slot:cell(height)="data">
                <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? data.value+" cm" : 'n/a' }} </span>
            </template>

            <template v-slot:cell(weight)="data">
                <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? data.value + " " + data.item.weight_unit : 'n/a' }} </span>
            </template>

            <template v-slot:cell(bmi)="data">
                <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? data.value : 'n/a' }} </span>
            </template>

            <template v-slot:cell(waist)="data">
                <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? data.value+" cm" : 'n/a' }} </span>
            </template>

            <template v-slot:cell(hip)="data">
                <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? data.value+" cm" : 'n/a' }} </span>
            </template>

            <template v-slot:cell(glucose)="data">
                <span :class="{ 'small font-italic text-muted': !data.value }">{{ data.value ? data.value+" mmol/L" : 'n/a' }} </span>
            </template>

            <template v-if="checkUserPermission('measurements', 'can_delete')" v-slot:cell(actions)="row">
                <b-button size="sm" variant="danger" @click="onRemoveItem(row.item)" class="mr-1"><i class="fa-solid fa-xmark"></i></b-button>
            </template>

            <div slot="table-busy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>

            <template v-slot:empty="scope">
                <div class="small text-muted text-center my-2">
                    <span>n/a</span>
                </div>
            </template>

            <template v-slot:custom-foot="scope">
                <b-tr class="border-top">
                    <b-td colspan="6" class="pt-3 align-middle" variant="light" style="background: #fff;">
                        Showing {{ startIndex }} to {{ endIndex }} of {{ totalRows }}
                    </b-td>
                    <b-td colspan="2" class="pt-3 text-right align-middle" variant="light" style="background: #fff;">
                        <b-pagination class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="right" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
                    </b-td>
                </b-tr>
            </template>
        </b-table>
    </b-overlay>
</b-tab>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex"
import TabExtension from "../TabExtension"

export default TabExtension.extend({
    name: "measurements",
    data() {
        return {
            addNew: false,
            form: null,

            fields: [
                { key: 'created', label: 'Date/Time', class: 'small align-middle', formatter: (value) => this.toLocaleDate(value) },
                { key: 'weight', label: 'Weight', class: 'small align-middle text-center' },
                { key: 'height', label: 'Height', class: 'small align-middle text-center' },
                { key: 'bmi', label: 'BMI', class: 'small align-middle text-center' },
                { key: 'waist', label: 'Waist circumference', class: 'small align-middle text-center' },
                { key: 'hip', label: 'Hip circumference', class: 'small align-middle text-center' },
                { key: 'glucose', label: 'Glucose', class: 'small align-middle text-center' },
                
                { key: "actions", label: "", class: "align-middle text-right" },
            ],

            currentPage: 1,
            perPage: 5,
            sortedBy: 'created',
            sortedDesc: true,

            isLocked: true,
            isDirty: false,
            hasErrors: false,

            confirm: false,
            processing: false,
            selected: null
        }
    },
    computed: {
        ...mapState('catalogue', {
            catalogue_defaults: state => state.catalogue_defaults,
            body_weight_measurement_methods: state => state.body_weight_measurement_methods,
            body_height_measurement_methods: state => state.body_height_measurement_methods
        }),
        ...mapGetters('health', ['getPatientMeasurements']),
        items() {
            return this.getPatientMeasurements()
        },
        ...mapState('patients', {
            current_consult: state => state.current.current_consult,
        }),
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
            
        // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        },
        totalRows() {
            return this.items.length
        },
        startIndex() {
            return (this.currentPage - 1) * this.perPage + 1
        },
        endIndex() {
            var end = this.totalRows
            if (this.perPage < this.totalRows) {
                end = this.perPage * this.currentPage
                if (end > this.totalRows) {
                    end = this.totalRows
                }
            }
            return end
        }
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.$emit('dirty', this.$vnode.key)
                }else{
                    if (this.formValid)
                        this.$emit('saved', this.$vnode.key)
                }
            }
        },
        catalogue_defaults: {
            immediate: true,
            handler(val) {
                this.newFormWithDefaults(val)
            }
        },
        addNew(val) {
            if (!val) {
                this.reset()
            }
        }
    },
    methods: {
        ...mapActions('catalogue', ['getCatalogueItem']),
        ...mapActions('health', ['getMeasurementsByPatientId', 'createPatientMeasurementsWithOrWithoutConsult', 'deletePatientMeasurements']),
        onRemoveItem(item) {
            this.confirm = true
            this.processing = false
            this.selected = item
        },
        onConfirmed(item) {

            this.processing = true

            this.deletePatientMeasurements(item.id).then(() => {
                this.notify("Patient measurements removed", "Patient measurements was removed successfully")
                this.confirm = false
                this.processing = false
                this.selected = null

            })
            .catch(errors => {
                this.notify("Error", "Something went wrong", 'danger')
                this.confirm = false
                this.processing = false
                this.selected = null
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
            this.processing = false
            this.selected = null
        },        
        onFormSubmit() {
            this.createPatientMeasurementsWithOrWithoutConsult(this.form.data()).then(() => {
                this.reset()
                this.notify("Patient measurements created", "Patient measurements was created successfully")
            })
            .catch(errors => {
                if (errors.response) {
                    this.$refs.observer.setErrors(errors.response.data)
                }
            })
        },
        newFormWithDefaults(defaults) {
            this.form = Form.create({
                height: "",
                height_unit: defaults.body_height_unit,
                weight: "",
                weight_unit: defaults.body_weight_unit,
                waist: "",
                hip: "",
                glucose: "",
                consistency_token: null
            })
        },
        reset() {
            this.newFormWithDefaults(this.catalogue_defaults)
            this.$refs.observer.reset()

            this.$nextTick(() => {
                this.hasErrors = false
                this.addNew = false
                this.$emit('saved', this.$vnode.key)
            })
        }
    },
    async mounted() {
        await this.getCatalogueItem('body_weight_measurement_methods')
        await this.getCatalogueItem('body_height_measurement_methods')

        this.getMeasurementsByPatientId()
    }
})
</script>