<template>
    <b-card no-body class="card-accent-warning rounded-0 border-left-0 border-right-0 mb-0 bg-gradient-light">
        <validation-observer ref="observer" v-slot="{ passes }">
            <b-form @submit.prevent="passes(onFormSubmit)" novalidate>
            <b-tabs pills card content-class="border-left-0 border-bottom-0 border-right-0" active-nav-item-class="bg-warning">
                <template #tabs-end>
                    <!-- <b-nav-item link-classes="text-warning" href="#" role="presentation" @click="showEpisodeModal">Choose Episode</b-nav-item> -->
                    <b-button v-if="isDirty || hasErrors" type="button" variant="danger" class="ml-auto mr-1" @click="reset">
                        <i class="fa-solid fa-ban"></i> Reset
                    </b-button>
                    <b-button v-show="isDirty" type="submit" variant="success">
                        <i class="fa-regular fa-circle-dot fa-beat"></i> Save Episode
                    </b-button>
                </template>
                <b-tab active>
                    <template slot="title">
                        <i class="fa-sharp fa-solid fa-heart-pulse"></i> Episode
                    </template>
                    <b-overlay :show="confirm" no-wrap @shown="onShown">
                        <template #overlay>
                            <div v-if="$store.getters['loading/isLoading']('episode')" class="d-flex align-items-center">
                                <strong>Updating...</strong>
                                <b-spinner class="ml-auto"></b-spinner>
                            </div>
                            <div v-else ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label">
                                <p class="text-center">
                                    <i class="fa-solid fa-cloud-arrow-up font-sm text-success"></i> <strong class="text-success">UPDATE EPISODE ? </strong>
                                </p>
                                <div class="text-center">
                                    <b-button variant="outline-danger" class="mr-3" @click="onCancel">Cancel</b-button>
                                    <b-button variant="outline-success" @click="onConfirmed">Confirm</b-button>
                                </div>
                            </div>
                        </template>
                    </b-overlay>
                    <validation-provider vid="name" v-slot="{ errors }">
                    <b-form-group
                        label="Episode Name"
                        label-for="name"
                        :invalid-feedback="errors[0]"
                        :state="errors[0] ? false : null">
                        <b-input-group>
                            <b-form-input :state="icpc ? true : errors[0] ? false : null" class="squared-bottom" id="name" name="name" v-model="form.name" placeholder="Generic Episode" autocomplete="off" />
                            <b-input-group-append>
                                <b-input-group-text>
                                    {{ toLocaleDate(episode.start) }}
                                </b-input-group-text>
                            </b-input-group-append>
                        </b-input-group>
                        <validation-provider vid="icpc_id" v-slot="{ errors }">
                          <b-button
                            block
                            type="button"
                            class="squared-top"
                            :variant="errors[0] ? 'danger' : (icpc ? 'success' : 'primary')"
                            @click="icpc ? unsetICPC() : showICPCModal()"
                          >
                            <i :class="icpc ? 'fa-solid fa-ban' : 'fa-solid fa-seal'"></i>
                            {{ icpc ? icpc : 'ICPC Episode' }}
                          </b-button>
                        </validation-provider>
                    </b-form-group>
                    </validation-provider>
                    <b-form-group
                        label="Recent Episodes:">
                        <b-list-group>
                            <template v-if="hasActiveEpisodes">
                            <b-list-group-item href="#" @click="setSelectedEpisode(episode)" class="p-0 flex-column align-items-start" v-for="episode in activeEpisodes" :key="`episode-${episode.id}`">
                                <div v-if="episode.bookmarked" class="position-relative">
                                    <i style="top:0;left:8px;" class="position-absolute fa-sharp fa-solid fa-bookmark text-warning"></i>
                                </div>
                                <div class="ml-2 p-2">
                                    <div class="d-flex w-100 justify-content-between">
                                        <h6 class="mb-1">{{ episode.name }}</h6>
                                        <small>{{ toLocaleDate(episode.start) }}</small>
                                    </div>

                                    <small v-if="episode.icpc">{{ episode.icpc.code }} - {{ episode.icpc.name }}</small>
                                </div>
                            </b-list-group-item>
                            </template>
                            <template v-if="hasEpisodes">
                                <b-list-group-item href="#" @click="showEpisodeModal">Show more</b-list-group-item>
                            </template>
                        </b-list-group>
                    </b-form-group>
                </b-tab>
            </b-tabs>
            </b-form>
        </validation-observer>
    </b-card>
</template>
<script>
import Vue from 'vue'
import BaseExtension from '@/views/BaseExtension'
import { mapGetters, mapActions } from 'vuex'

import EpisodeModal from './EpisodeModal'
import ICPCModal from '../catalogue/ICPCModal'

export default BaseExtension.extend({
    name: 'decursus-episode',
    props: ['limit'],
    data() {
        return {
            form: new Form({
                name: "",
                icpc_id: null,
                icpc: null,
                consistency_token: null
            }),

            confirm: false,

            isDirty: false,
            hasErrors: false,

            currentEpisode: null
        }
    },
    computed: {
        ...mapGetters('episodes', ['getCurrentEpisode', 'getEpisodes', 'getActiveEpisodes']),
        episode() {
            return this.getCurrentEpisode()
        },
        episodes() {
            return this.getEpisodes()
        },
        activeEpisodes() {
            return this.getActiveEpisodes().filter(episode => episode.id !== this.episode.id).slice(0, this.limit)
        },
        hasActiveEpisodes() {
            return this.activeEpisodes.length > 0
        },
        hasEpisodes() {
            return this.episodes.length > 0
        },
        icpc() {
            return this.form.icpc_id ? `${this.form.icpc.code} ${this.form.icpc.name}` : null
        },
        formValid() {
           if (typeof this.$refs.observer == "undefined")
                return true
            // loop over all contents of the fields object and check if they exist and valid.
            return Object.keys(this.$refs.observer.fields).every(field => {
                return this.$refs.observer.fields[field] && this.$refs.observer.fields[field].valid
            })
        }
    },
    watch: {
        episode: {
            immediate: true,
            handler(val) {
                this.form = Form.create(val)
                // this.validateEpisode()
            }
        },
        form: {
            deep: true,
            handler(val) {
                this.isDirty = this.form.dirty()
                this.hasErrors = !this.formValid

                if (this.isDirty) {
                    this.dirty(this.$vnode.key)
                    this.$emit('validated', false)
                }else{
                    if (this.formValid)
                        this.saved(this.$vnode.key)
                        this.$emit('validated', true)
                }
            }
        }
    },
    methods: {
        ...mapActions('episodes', ['updateEpisode', 'setEpisode']),
        onFormSubmit() {
            this.confirm = true
        },
        onConfirmed() {
            this.updateEpisode(this.form.data()).then(() => {
                this.notify("Episode updated", "Patient episode was updated successfully")
                this.confirm = false
                this.currentEpisode = this.episode
            })
            .catch(errors => {
                if (errors.response) {
                    this.notify("Error", "Something went wrong", 'danger')
                    this.$refs.observer.setErrors(errors.response.data)
                    this.hasErrors = true
                    this.confirm = false
                }
            })
        },
        onShown() {
            // Focus the dialog prompt
            this.$refs.dialog.focus()
        },
        onCancel() {
            this.confirm = false
        },
        showICPCModal() {
            var ComponentClass = Vue.extend(ICPCModal)
            var instance = new ComponentClass({ parent: this })

            instance.$on("hidden", () => {
                instance.$destroy()
            })

            instance.$on("selected", (icpc) => {
                this.form.populate({icpc_id: icpc.id})
                this.form.populate({icpc: icpc})
            })

            instance.$mount()
        },

        unsetICPC() {
            this.form.populate({icpc: null})
            this.form.populate({icpc_id: null})
        },

        setSelectedEpisode(episode) {
            this.setEpisode(episode)
            this.$nextTick(() => {
                this.isDirty = true
                this.$emit('validated', false)
            })
        },

        showEpisodeModal() {
            var ComponentClass = Vue.extend(EpisodeModal)
            var instance = new ComponentClass({ parent: this, propsData: { current: this.episode } })

            instance.$on("hidden", () => {
                instance.$destroy()
            })

            instance.$on("selected", (episode) => {
                this.setEpisode(episode)
                this.$nextTick(() => {
                    this.isDirty = true
                    this.$emit('validated', false)
                })
            })

            instance.$mount()
        },
        reset() {
            this.form.reset()
            this.$refs.observer.reset()

            this.setEpisode(this.currentEpisode)

            this.$nextTick(() => {
                this.hasErrors = false
                this.saved(this.$vnode.key)
            })
        },
        // validateEpisode() {
        //     if(this.form.name !== "") {
        //     // if(this.form.icpc_id !== null && this.form.name !== "") {
        //         this.$emit('validated', true)
        //     }else{
        //         // this.$emit('validated', false)
        //     }
        // }
    },
    mounted() {
        this.currentEpisode = this.episode
    }
})
</script>