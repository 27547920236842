import session from '@/shared/session'

const state = {
	current: {},
	patient: {
		decursus: []
	}
}

// getters
const getters = {
	getCurrentDecursus: (state) => () => {
		return state.current
	},
	getPatientPreviousDecursus: (state) => () => {
		return state.patient.decursus
	}
}

// actions
const actions = {
	async getDecursusByPatientId({ commit, state, rootState }) {
		const decursus  = await session
			.get(`/api/patients/${rootState.patients.current?.id}/decursus/`)

		commit("set_previous_decursus", decursus.data)

		return state.patient.decursus
	},

	async getDecursusByConsultationId({ commit, state }, id) {
		const [previous, current] = await Promise.all([
			session.get(`/api/planning/consultations/${id}/decursus/`),
			session.get(`/api/planning/consultations/${id}/current_decursus/`),
		])

		commit("set_current", current.data)
		commit("set_previous_decursus", previous.data)

		return state.current
	},

	updateDecursus({ commit, dispatch, state }, data) {
		dispatch("loading/load", "decursus", { root: true })
		return session
			.put(`/api/decursus/${state.current?.id}/`, data)
			.then((current) => {
				commit("set_current", current.data)
				dispatch('schedule/updateConsultationEpisode', data.episode, { root: true })
				return dispatch('getDecursusByPatientId')
			})
			.then(() => {
				return state.current
			})
			.finally(() => {
				dispatch("loading/end", "decursus", { root: true })
			})
	}
}

// mutations
const mutations = {
	set_current(state, current) {
		state.current = current
	},
	set_previous_decursus(state, decursus) {
		state.patient.decursus = decursus
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}